import "./src/styles/global.css"
import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import Theme from "./src/themes/theme"
import getDeviceInfo from "./src/utils/getDeviceInfo"

import "./language-tabs.css"
import { Seo } from "./src/components"
import AllFreeTools from "./AllFreeTools_Copy"
import {
  EXTERNAL_DOCUMENT_REFERRER,
  NEW_DOMAIN_TOOLS,
} from "./src/utils/constants"
import { __ENV_DEV__ } from "./src/utils/__ENV_DEV__"
import fetchDataAndShowSalesPopup from "./src/utils/fetchDataAndShowSalesPopup"
// Highlighting for code blocks
// import("prismjs/themes/prism.css")
// import("prismjs/themes/prism-solarizedlight.css")
// import("prismjs/themes/prism-coy.css")
import("prismjs/themes/prism-tomorrow.css")

const GlobalStyles = createGlobalStyle`
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body, html{
  font-family: ${props => props.theme.fonts.main};
  height: 100%;
  /* background-color: ${props => props.theme.colors.white2}; */
}
`

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyles />
    <Seo />
    {element}
  </ThemeProvider>
)
export const onInitialClientRender = () => {
  // document.getElementById("___loader").style.display = "none"

  addAff()
  // for impact.com verification
  AddImpact()

  addLmnSqBuyOverlay()

  // add crisp chat
  addCrispChat()
}

const addCrispChat = () => {
  window.$crisp = []
  window.CRISP_WEBSITE_ID = "503e7464-459b-47f6-9dca-dcdf63e29c6b"

  const s = document.createElement("script")
  s.src = "/crisp-chat.js"
  s.async = 1
  s.defer = true
  document.head.appendChild(s)
}

const addAff = () => {
  window.lemonSqueezyAffiliateConfig = { store: "charm-shot-ai" }
  const script = document.createElement("script")
  // script.src = "https://lmsqueezy.com/affiliate.js"
  script.src = "/lmsq-affiliate.js"
  script.defer = true
  document.head.appendChild(script)
}

const addLmnSqBuyOverlay = () => {
  const script = document.createElement("script")
  script.src = "/lmsq-buy-overlay.js"
  script.async = true
  script.defer = true
  document.head.appendChild(script)
}

const AddImpact = () => {
  const meta = document.createElement("meta")
  // Set the name and content attributes
  meta.setAttribute("name", "impact-site-verification")
  meta.setAttribute("content", "d23db4a1-5242-46ec-9401-dd85d0491ca5")
  document.head.appendChild(meta)
}

const customHotJarConfig = location => {
  const isToolsPage = (location.pathname || "")
    .toLowerCase()
    .includes("/tools/")

  if (isToolsPage) {
    // console.log("face score hotjar stopped")
    window.hj = () => {}
    window._hjSettings = () => {}
  }

  //
}

// In gatsby-browser.js
// don't touch this code, it's used for redirect
// don't use onPreRouteUpdate, because it runs before seo components get dynamic props
export const onRouteUpdate = ({ location, prevLocation }) => {
  fetchDataAndShowSalesPopup(location)

  // stop hotjar tracking in some specific pages like in tools page
  customHotJarConfig(location)

  // get referrer, utc time, pathname of customer entry for lmnsqz to record it in chekcout custom data
  saveDocumentReferrer(location)

  if (typeof window !== "undefined") {
    let locPathname = ""

    if (typeof window === "object") {
      locPathname = window.location.pathname || ""
      locPathname = locPathname.replace(/\/+$/, "")

      locPathname = !!locPathname ? locPathname : "/"
    }

    let toolLinkRedirect = ""

    AllFreeTools.map(toolObj => {
      // do not suggest the same tool on the same tool page
      const isSameTool =
        typeof window === "object" &&
        locPathname.includes((toolObj.link || "").replace(/\/+$/, ""))
      // console.log({ isSameTool })

      if (isSameTool) {
        toolLinkRedirect = toolObj.link
      }
    })

    let finalToolsUrl =
      locPathname &&
      toolLinkRedirect &&
      locPathname.startsWith(toolLinkRedirect.replace(/\/+$/, ""))
        ? locPathname
        : null
    if (locPathname === "/tools" && !finalToolsUrl) {
      finalToolsUrl = locPathname
    }
    finalToolsUrl = finalToolsUrl ? `${NEW_DOMAIN_TOOLS}${finalToolsUrl}` : null

    if (!finalToolsUrl) {
      return
    }
    // console.log({ finalToolsUrl, locPathname })
    // TODO-uncomment this
    if (!__ENV_DEV__) {
      window.location.href = finalToolsUrl
    }
  }
}

const saveDocumentReferrer = location => {
  try {
    let docRef = localStorage.getItem(EXTERNAL_DOCUMENT_REFERRER) || ""

    // if already exist then keep it
    if (!!docRef) {
      return
    }

    let pathName = location.pathname || ""
    pathName = pathName.substring(0, 300)

    docRef = document.referrer || ""
    docRef = docRef.substring(0, 100)

    const { osBrowser, availableResolution, timezone, cpu } = getDeviceInfo()

    const otherInfo = `_os_browser_${osBrowser}_resolution_${availableResolution}_timezone_${timezone}_cpu_${cpu}`

    docRef =
      docRef +
      "_time_utc_" +
      new Date().toUTCString() +
      "_pathname_" +
      pathName +
      otherInfo

    localStorage.setItem(EXTERNAL_DOCUMENT_REFERRER, docRef)
  } catch (err) {
    console.log("Error: ", err.message)
  }
}

import { ClientJS } from "clientjs"

const getDeviceInfo = () => {
  if (typeof window === "undefined" || typeof navigator === "undefined") {
    // This means the code is running on the server or in an environment where window and navigator are not defined.
    return {}
  }

  const client = new ClientJS()

  let os = ""
  let browser = ""
  let isWin = false
  let isMac = false
  let isLinux = false

  let isAndroid = false
  let isIOS = false

  let isChrome = false
  let isSafari = false

  // for license name
  let osBrowser

  // fingerprint
  let fid = ""

  let availableResolution = ""

  let timezone = ""

  let cpu = ""

  try {
    // TODO- this fid changes field time to time, so the license in license collection if differs, it rejects, remove fid tracking?
    // const fid = '' + client.getFingerprint();
    os = "" + client.getOS()

    browser = "" + client.getBrowser()

    osBrowser = os + ", " + browser

    os = os.toLowerCase()
    browser = browser.toLowerCase()

    availableResolution = "" + client.getAvailableResolution()

    timezone = "" + client.getTimeZone()

    cpu = "" + client.getCPU()

    if (os.includes("mac")) {
      isMac = true
    } else if (os.includes("windows")) {
      isWin = true
    } else if (os.includes("linux")) {
      isLinux = true
    } else if (os.includes("ios")) {
      isIOS = true
    } else if (os.includes("android")) {
      isAndroid = true
    }

    if (browser.includes("chrome")) {
      isChrome = true
    } else if (browser.includes("safari")) {
      isSafari = true
    }

    // fid is a string

    fid = client.getFingerprint()
  } catch (err) {
    console.log(err)
  }

  // console.log({ os, browser, osBrowser })

  return {
    osBrowser: osBrowser || "random",
    os,
    browser,

    isWin,
    isMac,
    isLinux,

    isAndroid,
    isIOS,

    isChrome,
    isSafari,

    availableResolution,
    timezone,
    cpu,

    fid,
  }
}

export default getDeviceInfo

import { SALES_POPUP_INTERVAL_ID } from "./constants"

//  ------------------- start of sales pop up ------------------------------
const isDevEnv = process.env.NODE_ENV === "development"

const SALES_POPUP_DURATION_SEC = 12 //for how long a popup displayed
const SALES_POPUP_OFFSET_SEC = 4 //between two popups gap silence
const SALES_POPUP_INIT_DELAY_SEC = 5 //show popup after page loads, after a delay
const isMobile = typeof window !== "undefined" && window.innerWidth < 700

const fetchDataAndShowSalesPopup = async (location, show_popup = false) => {
  const isPricingPage = (location.pathname || "")
    .toLowerCase()
    .includes("/pricing")

  const isHomePage = (location.pathname || "/").toLowerCase() === "/"

  if (!isPricingPage && !isHomePage && !show_popup) {
    return
  }

  const SERVER_URL = process.env.GATSBY_SERVER_URL

  try {
    const response = await fetch(`${SERVER_URL}/api/v1/charmshotai/users/sub`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const result = await response.json()
    const subArr = (result || {}).data || []

    if (isDevEnv) {
      subArr = [
        { country: "Norway", city: "Oslo" },
        { country: "United States", city: "Mentor" },
        { country: "France" },
        { country: "Japan", city: "Tokyo" },
        { country: "Canada", city: "Montreal" },
      ]
    }

    showSalesPopup(subArr, location)
  } catch (error) {
    console.error("Error fetching data:", error)
  }
}
let currentIndex = 0

const showSalesPopup = async (subArr, location) => {
  // wait 5 sec before showing popup
  await new Promise(res => setTimeout(res, SALES_POPUP_INIT_DELAY_SEC * 1000))

  // Start the popup cycle after a short delay to ensure the DOM is ready
  setTimeout(() => {
    showPopup(subArr[currentIndex], location)
    currentIndex = (currentIndex + 1) % subArr.length
    window[SALES_POPUP_INTERVAL_ID] = setInterval(
      () => {
        showPopup(subArr[currentIndex], location)
        currentIndex = (currentIndex + 1) % subArr.length
      },
      (SALES_POPUP_DURATION_SEC + SALES_POPUP_OFFSET_SEC) * 1000,
    )
  }, 1000)
}

const showPopup = (data, location) => {
  if (!data || !data.country) {
    console.log("empty sub")
    return
  }

  // Remove existing popup if any
  const existingPopup = document.getElementById("gatsby-popup")
  if (existingPopup) {
    document.body.removeChild(existingPopup)
  }

  const isPricingPage = (location.pathname || "")
    .toLowerCase()
    .includes("/pricing")

  const isToolPage = (location.pathname || "").startsWith("/tools/")

  const pricingUrl = isPricingPage
    ? "#"
    : `https://charmshotai.com/pricing${isToolPage ? "?tool=" + location.pathname : ""}`

  // console.log({ isToolPage, location, pricingUrl, isPricingPage })

  // Create new popup
  const popup = document.createElement("div")
  popup.id = "gatsby-popup"
  popup.innerHTML = `<a href="${pricingUrl}" target="${isPricingPage ? "_self" : "_blank"}"><div style="background:oklch(18% 0.019 237.69);padding:${isMobile ? "10px" : "20px"};border:0.5px solid gray;border-radius:12px;box-shadow:0 0 10px rgba(0,0,0,0.5);font-size:${isMobile ? "12px" : "16px"}">
  <div style="color:#B387FA;">Someone in <span style="fontWeight:bolder;">${data.country}</span> purchased</div>
  <div style="color:oklch(70% 0.019 237.69);margin-top:4px;">Verified by <a href="https://lemonsqueezy.com/" target="_blank" rel="noopener noreferrer"><span style="fontWeight:bolder;color:#B387FA;"><img src="/verify.svg" alt="verify" style="height:24px;width:24px;display:inline;"/> Lemon Squeezy</span></a></div>
  </div></a>`

  popup.style.cssText = `
    position: fixed;
    bottom: ${isMobile ? "10px" : "20px"};
    left: ${isMobile ? "10px" : "20px"};
    z-index: 1000;
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    transform: translateX(-100%);
    opacity: 0;
  `

  document.body.appendChild(popup)

  // Trigger reflow to ensure the initial state is applied before transitioning
  // popup.offsetHeight

  // Start entrance animation
  setTimeout(() => {
    popup.style.transform = "translateX(0)"
    popup.style.opacity = "1"
  }, 50)

  // Automatically remove popup after 12 seconds with animation
  setTimeout(() => {
    if (document.body.contains(popup)) {
      popup.style.transform = "translateX(-100%)"
      popup.addEventListener("transitionend", () => {
        if (document.body.contains(popup)) {
          document.body.removeChild(popup)
        }
      })
    }
  }, SALES_POPUP_DURATION_SEC * 1000)
}

//  ------------------- end of sales pop up ------------------------------

export default fetchDataAndShowSalesPopup

import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import AllFreeTools from "../utils/AllFreeTools"
import { NEW_DOMAIN_TOOLS } from "../utils/constants"

export const Seo = ({ description, keywords, title, image, url, author }) => {
  let locPathname = ""

  if (typeof window === "object") {
    locPathname = window.location.pathname || ""
    locPathname = locPathname.replace(/\/+$/, "")

    locPathname = !!locPathname ? locPathname : "/"
  }

  let schemaMarkupObj = {}

  let toolLinkRedirect = ""

  AllFreeTools.map(toolObj => {
    // do not suggest the same tool on the same tool page
    const isSameTool =
      typeof window === "object" &&
      locPathname.includes((toolObj.link || "").replace(/\/+$/, ""))
    // console.log({ isSameTool })

    if (isSameTool) {
      schemaMarkupObj = toolObj.schema_markup || {}
      toolLinkRedirect = toolObj.link
    }
  })

  let showSchemaMarkup = Object.keys(schemaMarkupObj || {}).length > 0

  // console.log({ showSchemaMarkup, schemaMarkupObj })

  // schema
  let schemaName = schemaMarkupObj.name
  let schemaDesc = schemaMarkupObj.desc
  let schemaVersion = schemaMarkupObj.version
  let schemaFeatureList = schemaMarkupObj.featureList
  let schemaFileFormat = schemaMarkupObj.fileFormat
  let schemaAggregateRating =
    Object.keys(schemaMarkupObj.aggregateRating || {}).length > 0
      ? schemaMarkupObj.aggregateRating || {}
      : false

  if (!image) {
    showSchemaMarkup = false
  }

  /* remove duplicate script tag */

  // if (typeof window === "object" && showSchemaMarkup) {
  //   const scriptList = document.querySelectorAll(
  //     'script[data-key="my-schema-markup"]',
  //   )

  //   console.log(scriptList)

  //   for (let i = 0; i < scriptList.length; i++) {
  //     scriptList[i].parentNode.removeChild(scriptList[i])
  //   }
  //   // console.log({ showSchemaMarkup })
  //   // if (scriptList.length > 1) {
  //   //   showSchemaMarkup = false
  //   // }

  //   console.log({
  //     scriptList: document.querySelectorAll(
  //       'script[data-key="my-schema-markup"]',
  //     ),
  //     showSchemaMarkup,
  //   })
  // }

  // console.log(schemaAggregateRating)

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const site_name = data.site.siteMetadata.title
        const metaTitle = title || data.site.siteMetadata.title
        const metaDescription =
          description || data.site.siteMetadata.description
        const metaAuthor = author || data.site.siteMetadata.author
        let metaUrl = `${url || data.site.siteMetadata.url}` || ""

        metaUrl = metaUrl.startsWith("http")
          ? metaUrl
          : `https://charmshotai.com/${metaUrl.replace(/^\/+/, "")}`

        let metaImage =
          `https://charmshotai.com${image || data.logo.publicURL}` || ""

        metaImage = metaImage.startsWith("http")
          ? metaImage
          : `https://charmshotai.com/${metaImage.replace(/^\/+/, "")}`

        const metaKeywords = keywords || [
          "Design Images Free",
          "Graphic Design Tool",
        ]

        // console.log({
        //   metaTitle,
        //   metaDescription,
        //   metaImage,
        //   metaUrl,
        // })

        // console.log({ locPathname })

        if (locPathname === "/") {
          showSchemaMarkup = true

          schemaName = metaTitle
          schemaDesc = metaDescription
          schemaVersion = "1.0"
          schemaFeatureList = [
            "Enhance Images with backgrounds",
            "Balance text in Images",
            "Hide sensitive information",
            "Overlay text automatically",
            "Extract text from images",
            "Customize Images with shapes and text styles",
          ]
          schemaFileFormat = ["image/*"]
          schemaAggregateRating = {
            ratingValue: "5",
            ratingCount: "101",
            bestRating: "5",
            worstRating: "1",
          }
        }

        let finalToolsUrl =
          url && toolLinkRedirect && url.startsWith(toolLinkRedirect)
            ? url
            : null
        if (url === "/tools/" && !finalToolsUrl) {
          finalToolsUrl = url
        }
        finalToolsUrl = finalToolsUrl
          ? `${NEW_DOMAIN_TOOLS}${finalToolsUrl}`
          : null

        // console.log({
        //   toolLinkRedirect,
        //   locPathname,
        //   url,
        //   metaUrl,
        //   finalToolsUrl,
        // })

        return (
          <Helmet
            title={title}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `og:site_name`,
                content: site_name, // Assuming the site name is the same as the site title
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                name: `twitter:creator`,
                content: metaAuthor,
              },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: metaImage,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: `keywords`,
                    content: metaKeywords.join(`, `),
                  }
                : [],
            )}
          >
            {/* only move tools page */}
            {/* Add this line */}
            {finalToolsUrl && (
              <link rel="canonical" href={`${finalToolsUrl}`} />
            )}

            {!!showSchemaMarkup && (
              <script type="application/ld+json" data-key="my-schema-markup">
                {JSON.stringify({
                  "@context": "https://schema.org",
                  "@type": "SoftwareApplication",
                  name: schemaName,
                  applicationCategory: "MultimediaApplication",
                  operatingSystem: "Any",
                  offers: {
                    "@type": "Offer",
                    price: "0",
                    priceCurrency: "USD",
                  },
                  description: schemaDesc,
                  softwareVersion: schemaVersion,
                  fileFormat: schemaFileFormat,
                  featureList: schemaFeatureList,
                  screenshot: metaImage,
                  url: finalToolsUrl || metaUrl,
                  provider: {
                    "@type": "Organization",
                    name: finalToolsUrl ? "ImageTools Hub" : site_name,
                    url: finalToolsUrl
                      ? NEW_DOMAIN_TOOLS
                      : "https://charmshotai.com",
                  },

                  ...(Object.keys(schemaAggregateRating || {}).length > 0 && {
                    aggregateRating: {
                      "@type": "AggregateRating",
                      ...schemaAggregateRating,
                    },
                  }),
                })}
              </script>
            )}
          </Helmet>
        )
      }}
    />
  )
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        image
        url
      }
    }
    logo: file(relativePath: { eq: "og/main.png" }) {
      publicURL
    }
  }
`

export const WEB_WORKER_ORIGIN_CHECK = "_upscale_csai"

// for summarize
export const WEB_WORKER_ORIGIN_CHECK_SUMMARIZE = "_summarize_csai"

// for tools
export const MONEY_TOTAL_SAVED_USING_CHARMSHOTAI = "__csai_all_total_saved"

// for bg removal
export const MONEY_SAVED_USING_BIBR = "__csai_bibr_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BIBR_PRICE_PER_UNIT = 0.15

// for bg blurring
export const MONEY_SAVED_USING_BIBB = "__csai_bibb_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BIBB_PRICE_PER_UNIT = 0.15

//for compressor
export const MONEY_SAVED_USING_BIC = "__csai_bic_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BIC_PRICE_PER_UNIT = 0.01

//face blurring
export const MONEY_SAVED_USING_ABIFB = "__csai_abifb_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_ABIFB_PRICE_PER_UNIT = 0.05

// for upscaler
export const MONEY_SAVED_USING_BIU = "__csai_biu_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BIU_PRICE_PER_UNIT = 0.2

//DP maker
export const MONEY_SAVED_USING_PFPM = "__csai_pfpm_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_PFPM_PRICE_PER_UNIT = 0.4

// for pdf to images converter
export const MONEY_SAVED_USING_BP2IC = "__csai_bp2ic_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BP2IC_PRICE_PER_UNIT = 0.01

// for pdf to images extracted
export const MONEY_SAVED_USING_BP2IE = "__csai_bp2ie_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BP2IE_PRICE_PER_UNIT = 0.01

//for resizer
export const MONEY_SAVED_USING_BIR = "__csai_bir_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BIR_PRICE_PER_UNIT = 0.01

//for flip images
export const MONEY_SAVED_USING_BFI = "__csai_bfi_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BFI_PRICE_PER_UNIT = 0.01

//image blurring
export const MONEY_SAVED_USING_BIB = "__csai_bib_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BIB_PRICE_PER_UNIT = 0.01
//image transparency
export const MONEY_SAVED_USING_BIT = "__csai_bit_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BIT_PRICE_PER_UNIT = 0.01

//for cropper
export const MONEY_SAVED_USING_BICRP = "__csai_bicrp_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BICRP_PRICE_PER_UNIT = 0.01

//face analyser
export const MONEY_SAVED_USING_FAS = "__csai_fas_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_FAS_PRICE_PER_UNIT = 0.02

//for image converter
export const MONEY_SAVED_USING_BICONVERT = "__csai_biconvert_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BICONVERT_PRICE_PER_UNIT = 0.01

// for book summarizer
export const MONEY_SAVED_USING_BBS = "__csai_bbs_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BBS_PRICE_PER_UNIT = 0.08

//for image placeholder
export const MONEY_SAVED_USING_BIP = "__csai_bip_saved"
//update it utils/updateMoneySaved.js too
export const MONEY_SAVED_BIP_PRICE_PER_UNIT = 0.01

// new domain redirect for tools
//it's defined in gatsby-node.js too, exact same value
export const NEW_DOMAIN_TOOLS = "https://imagetoolshub.com"

export const AUDIO_TOOLS_HUB_LINK = "https://audiotoolshub.com"

// external referrer i.e. document.referrer
export const EXTERNAL_DOCUMENT_REFERRER = "_ext_doc_referrer"

// sales popup interval id
export const SALES_POPUP_INTERVAL_ID = "_sales_popup_interval_id"
